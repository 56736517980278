var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type == 'add')?_c('div',[_c('a-form-model',{attrs:{"model":_vm.formInline},on:{"submit":_vm.handleSubmit},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('a-form-model-item',{attrs:{"label":"所属公司"}},[_c('a-select',{staticClass:"search-expand-value",model:{value:(_vm.formInline.mtBcId),callback:function ($$v) {_vm.$set(_vm.formInline, "mtBcId", $$v)},expression:"formInline.mtBcId"}},_vm._l((_vm.filters.GetCompanies),function(opt){return _c('a-select-option',{key:opt.bc_id,attrs:{"value":opt.bc_id}},[_vm._v(_vm._s(opt.bc_name))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"上级类别"}},[_c('a-tree-select',{attrs:{"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.filters.GetMaterial,"tree-default-expand-all":"","replaceFields":{
            key: 'mtId',
            title: 'mtName',
            value: 'mtId',
            children: 'children',
          }},model:{value:(_vm.formInline.mtSupId),callback:function ($$v) {_vm.$set(_vm.formInline, "mtSupId", $$v)},expression:"formInline.mtSupId"}})],1),_c('a-form-model-item',{attrs:{"label":"类别名称"}},[_c('a-input',{attrs:{"placeholder":""},model:{value:(_vm.formInline.mtName),callback:function ($$v) {_vm.$set(_vm.formInline, "mtName", $$v)},expression:"formInline.mtName"}})],1),_c('a-form-model-item',[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 提交 ")])],1)],1)],1):_vm._e(),(_vm.type == 'edit')?_c('div',[_c('a-form-model',{attrs:{"model":_vm.formInlineEdit},on:{"submit":_vm.handleSubmit},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('a-form-model-item',{attrs:{"label":"所属公司"}},[_c('a-select',{staticClass:"search-expand-value",attrs:{"disabled":""},model:{value:(_vm.formInlineEdit.mtBcId),callback:function ($$v) {_vm.$set(_vm.formInlineEdit, "mtBcId", $$v)},expression:"formInlineEdit.mtBcId"}},_vm._l((_vm.filters.GetCompanies),function(opt){return _c('a-select-option',{key:opt.bc_id,attrs:{"value":opt.bc_id}},[_vm._v(_vm._s(opt.bc_name))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"上级类别"}},[_c('a-tree-select',{attrs:{"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.filters.GetMaterial,"tree-default-expand-all":"","replaceFields":{
            key: 'mtId',
            title: 'mtName',
            value: 'mtId',
            children: 'children',
          }},model:{value:(_vm.formInlineEdit.mtSupId),callback:function ($$v) {_vm.$set(_vm.formInlineEdit, "mtSupId", $$v)},expression:"formInlineEdit.mtSupId"}})],1),_c('a-form-model-item',{attrs:{"label":"类别名称"}},[_c('a-input',{attrs:{"placeholder":""},model:{value:(_vm.formInlineEdit.mtName),callback:function ($$v) {_vm.$set(_vm.formInlineEdit, "mtName", $$v)},expression:"formInlineEdit.mtName"}})],1),_c('a-form-model-item',[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 提交 ")])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }